import { createContext, useContext } from 'react';

import appStore from './store/AppStore';

const stores = {
  appStore,
};

export const StoreContext = createContext(stores);

export const useStore = () => {
  return useContext<typeof stores>(StoreContext);
};

export default stores;
