import { useMsal } from '@azure/msal-react';
import { observer } from 'mobx-react-lite';
import { FC, ReactElement, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useStore } from '../stores';
import { replaceNull } from '../utils/null';

const loginRequest = {
  scopes: ['User.Read'],
};

const Home: FC = (): ReactElement => {
  const [isLogged, setIsLogged] = useState(false);
  const [searchParams] = useSearchParams();
  const token = useMemo(() => replaceNull(searchParams.get('token')), [searchParams]);
  const { appStore } = useStore();
  const { instance, accounts } = useMsal();

  const handleLogin = () => {
    instance
      .loginPopup(loginRequest)
      .then(() => {
        setIsLogged(true);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const handleActivate = () => {
    void instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        if (token && response.accessToken) void appStore.signUp(token, response.accessToken);
      });
  };

  return (
    <>
      {isLogged ? (
        appStore.isSuccess ? (
          'Congratulations, your subscription has been activated. You can close this window and return to Teams App'
        ) : appStore.isLoading ? (
          'Loading...'
        ) : appStore.errorMessage ? (
          appStore.errorMessage
        ) : token ? (
          <button onClick={handleActivate}>Activate Subscription</button>
        ) : (
          'Please provide auth token to sign up'
        )
      ) : (
        <button onClick={handleLogin}>Sign In with Microsoft SSO</button>
      )}
    </>
  );
};

export default observer(Home);
