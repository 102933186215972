import { observer } from 'mobx-react-lite';
import { FC, ReactElement, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

const Overlays: FC<any> = (): ReactElement => {
  return (
    <Suspense fallback={null}>
      <Outlet />
    </Suspense>
  );
};

export default observer(Overlays);
