import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import get from 'lodash/get';
import qs from 'qs';

import trimData from '../utils/trimData';

enum RequestMethod {
  get = 'get',
  post = 'post',
  put = 'put',
  patch = 'patch',
  delete = 'delete',
}

export type Response<T> = {
  data: T;
  status?: {
    text: AxiosResponse['statusText'];
    code: AxiosResponse['status'];
  };
};

async function baseRequest<T>(
  requestType: RequestMethod,
  url: string,
  body: Record<string, unknown> = {},
  queryParams: Record<string, unknown> | null = {},
  addHeaders: Record<string, unknown> | null = {},
  responseType?: ResponseType,
  onUploadProgress?: () => Record<string, unknown>,
): Promise<Response<T>> {
  const requestUrl =
    url +
    (queryParams
      ? qs.stringify(queryParams, { arrayFormat: 'repeat', encode: false, addQueryPrefix: true })
      : '');

  const headers = {
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    'Content-Type': 'application/json',
    ...addHeaders,
  };

  let generateData = '';

  if (headers['Content-Type'] === 'multipart/form-data' && body) {
    generateData = trimData(body);
  } else if (headers['Content-Type'] === 'application/json' && body) {
    generateData = JSON.stringify(trimData(body));
  }

  return axios({
    method: requestType,
    url: requestUrl,
    data: generateData || null,
    withCredentials: true,
    responseType,
    headers,
    onUploadProgress,
  } as AxiosRequestConfig)
    .then((response: AxiosResponse<any>) => {
      if (typeof response === 'string' || !response) {
        console.warn(
          'Type of response is text. Please, check this endpoint (%s) because it may lead to unexpected results.',
          requestUrl,
        );
        return { data: response };
      }

      return {
        data: response.data || {},
        status: {
          text: response.statusText,
          code: response.status,
        },
      };
    })
    .catch((error: AxiosError) => {
      const errorStatus = get(error, 'response.status', 500);
      const errorStatusText = get(error, 'response.statusText', null);

      if (error.response) {
        return Promise.reject({
          status: errorStatus,
          statusText: errorStatusText,
          data: get(error, 'response.data', null),
        });
      }

      return Promise.reject(error);
    });
}

export const getRequest = <T>(
  url: string,
  queryParams?: Record<string, unknown>,
  addHeaders?: Record<string, unknown> | null,
  responseType?: ResponseType,
): Promise<Response<T>> =>
  baseRequest<T>(RequestMethod.get, url, undefined, queryParams, addHeaders, responseType);

export const postRequest = <T>(
  url: string,
  body?: any,
  queryParams?: Record<string, unknown> | null,
  addHeaders?: Record<string, unknown>,
  onUploadProgress?: () => Record<string, unknown>,
): Promise<Response<T>> =>
  baseRequest<T>(
    RequestMethod.post,
    url,
    body,
    queryParams,
    addHeaders,
    undefined,
    onUploadProgress,
  );

export const patchRequest = <T>(
  url: string,
  body?: any,
  queryParams?: Record<string, unknown> | null,
  addHeaders?: Record<string, unknown> | null,
): Promise<Response<T>> => baseRequest<T>(RequestMethod.patch, url, body, queryParams, addHeaders);
