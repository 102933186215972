import { makeAutoObservable } from 'mobx';

import { activateSubscription, getPublisherToken, resolvePurchase } from '../api/subscription';

class AppStore {
  isLoading: boolean = false;
  isSuccess: boolean = false;
  errorMessage?: string = undefined;
  accessToken?: string = undefined;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  // ===================
  // ===== ACTIONS =====
  // ===================

  resetAppStore = () => {
    this.isLoading = false;
  };

  resolvePurchase = async (token: string) => {
    if (!this.accessToken) return;
    const result = await resolvePurchase(token, this.accessToken);

    return result;
  };

  activateSubscription = async (subscriptionId: number, planId: number, quantity: number) => {
    if (!this.accessToken) return;
    const body = { planId, quantity };

    await activateSubscription(subscriptionId, body, this.accessToken);
  };

  signUp = async (token: string, userToken: string) => {
    try {
      this.isLoading = true;
      const publisherTokenResponse = await getPublisherToken(userToken);
      this.accessToken = publisherTokenResponse.data.access_token;
      const result = await this.resolvePurchase(token);
      if (result) {
        await this.activateSubscription(
          result.data.subscription.id,
          result.data.subscription.planId,
          result.data.quantity,
        );
      }
      this.isLoading = false;
      this.isSuccess = true;
    } catch (error: any) {
      console.error(error);
      this.errorMessage = error.message as string;
      this.isLoading = false;
    }
  };
}

const appStore = new AppStore();

export default appStore;
