import { configure } from 'mobx';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Routes from './routes/Routes';

configure({ enforceActions: 'never' });

const router = createBrowserRouter(Routes);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
