import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import { toJS } from 'mobx';

const trimData = (body: any) => {
  if (!isPlainObject(body)) return body;
  const result: Record<string, unknown> = {};

  Object.keys(body).forEach((i) => {
    const elem = toJS(body[i]);
    result[i] = elem;
    if (isPlainObject(elem)) {
      result[i] = trimData(body[i]);
    }
    if (isArray(elem)) {
      result[i] = elem.map((i) => (typeof i === 'string' ? i.trim() : i));
    }
    if (typeof body[i] === 'string') {
      result[i] = body[i].trim();
    }
  });

  return result;
};

export default trimData;
