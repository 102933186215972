type NullToUndefined<T> = T extends null ? NonNullable<T> | undefined : T;

type UndefinedToNull<T> = T extends undefined ? NonNullable<T> | null : T;

export const unpreferredNull = null;

export const replaceNull = <T>(input: T): NullToUndefined<T> =>
  (input === unpreferredNull ? undefined : input) as NullToUndefined<T>;

export const replaceUndefined = <T>(input: T): UndefinedToNull<T> =>
  (input === undefined ? unpreferredNull : input) as UndefinedToNull<T>;
