import { RouteObject } from 'react-router-dom';

import Layout from '../components/Layout';
import Overlays from '../components/Overlays';
import ErrorPage from '../pages/ErrorPage';
import Home from '../pages/Home';

const routes: RouteObject[] = [
  {
    element: <Overlays />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Layout />,
        children: [
          {
            index: true,
            element: <Home />,
          },
        ],
      },
    ],
  },
];

export default routes;
